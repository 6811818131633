<template>
    <div class="documentation container">
        <Breads :links="breadLinks"/>
        <PageHeader
            class="title"
            :title="$t('Documentation')"
        />
        <div class="content">
            <div class="description">API доступне за таким посиланням: https://ekkapi.3k.group/</div>

            <div class="item">
                <div class="content-title">
                    Авторизація
                </div>
                <div class="description">
                    <span>Cinema-Token</span> — Для авторизації під кінотеатром. Додавання або повернення квитків для цього кінотеатру
                </div>
                <div class="description">
                    <span>Network-Token</span> — Для авторизації мережі. Додавання або повернення квитків для усіх кінотеатрів мережі
                </div>
            </div>
            <div class="item">
                <div class="content-title">
                    Додати квиток
                </div>
                <div class="description">
                    Запит на додавання квитка
                </div>
                <div class="description">
                    <span>Тип</span> — POST
                </div>
                <div class="description">
                    <span>Шлях</span> — /api/v1/tickets
                </div>
                <div class="description-wrapper">
                    Опис полей та їх тип:
                </div>
                <div class="description">
                    <span>id (string|int)</span>: Унікальний ідентифікатор квитка,
                </div>
                <div class="description">
                    <span>date (string)</span>: Дата та час продажу квитка,
                </div>
                <div class="description">
                    <span>price (float)</span>: Ціна білета,
                </div>
                <div class="description">
                    <span>session (string)</span>: Дата та час сеансу
                </div>
                <div class="description">
                    <span>type (enum: online|cashbox)</span>: Тип продажу (Онлайн/Касса),
                </div>
                <div class="description-wrapper">
                    <span>Приклад JSON структури запиту:</span>
                </div>

                <div class="code">
                <pre>
{
   "id":  "5492-2150-2515221",
   "date": "2021-08-08 12:32",
   "price":  350,
   "session": "2021-10-24 08:00",
   "type":  "online",
   "cinema_hall_id":  "546-22",
   "movie_id": "126.823.992"
}
                </pre>
                </div>
            </div>
            <div class="item">
                <div class="content-title">
                    Повернення квитка
                </div>
                <div class="description">
                    <span>Шлях</span> — /api/v1/return-ticket
                </div>
                <div class="description">
                    <span>Тип</span> — POST
                </div>
                <div class="description">
                    <span>id (string|int)</span>: Унікальний ідентифікатор квитка,
                </div>
                <div class="description-wrapper">
                    <span>Приклад JSON структури запиту:</span>
                </div>

                <div class="code">
                <pre>
{
   "id":  "5492-2150-2515221",
}
                </pre>
                </div>
            </div>
            <div>
                <div class="content-title">Помилки</div>
                <div class="description">
                    <span>401</span> — Некоректний авторизаційний токен
                </div>
                <div class="description">
                    <span>404</span> — Відсутні дані
                </div>
                <div class="description">
                    <span>422</span> — Відсутні дані
                </div>
                <div class="description-wrapper">
                    <span>Приклад відповіді:</span>
                </div>

                <div class="code">
                <pre>
{
   "message":  "Некоректний запит.",
  "errors":  [ {“Ключ поля”: "Опис помилки"}],
}
                </pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breads from "../../components/Breads";
import PageHeader from "../../components/PageHeader";

export default {
    name: "documentation",
    components: {
        Breads,
        PageHeader
    },
    data () {
        return {
            breadLinks: [
                {
                    label: this.$t('Documentation'),
                    to: { name: 'Documentation' }
                }
            ],
        }
    }
}
</script>

<style scoped lang="scss">
.documentation{
    .content{
        min-height: 500px;
        width: 100%;
        background-color: white;
        padding: 80px 112px;
        @media (max-width: 768px) {
            padding: 20px;
        }
        .item{
            border-bottom: 1px solid #cad9f3;
            border-radius: 2px;
            padding-bottom: 40px;
        }
        .content-title{
            font-size: 20px;
            font-weight: bold;
            color: #141213;
            margin-top: 40px;
            &:first-child{
                //margin-top: 0;
            }
        }
        .description{
            font-size: 18px;
            line-height: 1.5;
            color: #141213;
            margin-top: 18px;

            span{
                font-weight: bold;
            }
        }
        .description-wrapper{
            font-size: 20px;
            font-weight: bold;
            margin-top: 20px;
            border-top: 1px solid #edf3ff;
            border-radius: 2px;
            padding-top: 20px;
        }
        .code{
            border-radius: 2px;
            border: solid 1px #cad9f3;
            margin-top: 20px;
            padding: 16px;
            margin-bottom: 20px;
            pre{
                font-size: 18px;
                line-height: 1.5;
                color: #141213;
                margin-bottom: 0;
            }
        }
    }
    .title{
        margin-top: 12px;
    }
}
</style>
